import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import * as React from "react";

const IndexPage = () => {
    return (
        <Layout pageTitle='KTKT LTD '>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <div style={{
                    alignSelf: 'center',
                    marginTop: '20px'
                }}>
                    <ul>
                        <li>
                            <a href='/documents/disciplinary policy.pdf'>
                                <h1>
                                    📄 disciplinary policy
                                </h1>
                            </a>
                        </li>
                    </ul>

                </div>
                <div style={{
                    alignSelf: 'center',
                }}>
                    <Footer/>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage;
