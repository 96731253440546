import * as React from 'react'

const Footer = () => {
    return (
        <p style={{
            fontSize: 'medium',
            textAlign: 'center',
        }}>
            KTKT LTD
            <br/>
            The Bristol Office, 5 High Street, Bristol, United Kingdom, BS9 3BY
            <br/>
            VAT Number: GB 267 7619 56
            <br/>
            Company number: 10714473
            <br/>
            <br/>
            中国大陆客户请关注微信公众号"英国KTKT"
        </p>
    );
};

export default Footer;

