import * as React from 'react'
import bg from "../images/bg.jpg";
import './style.css';

const Layout = ({pageTitle, children}) => {
    return (
        <div
            style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
            }}
        >
            <title>{pageTitle || 'KTKT LTD'}</title>
            <main>
                {children}
            </main>
        </div>
    )
}
export default Layout
